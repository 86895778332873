import axios from '@/libs/axios'

export function reqGetUsers(payload) {
  return axios.get('api/admin/users', { params: payload })
}
export function reqGetUser(id) {
  return axios.get(`api/admin/users/${id}`)
}
export function reqCreateUser(payload) {
  return axios.post('api/admin/users', payload)
}
export function reqUpdateUser(id, payload) {
  return axios.post(`api/admin/users/${id}?_method=PATCH`, payload)
}
export function reqDeleteUser(id) {
  return axios.delete(`api/admin/users/${id}`)
}
